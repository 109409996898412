import countries from './countries';
import timezones from './timezones';
import languages from './languages';

let state = {
    menu: [{
        name: 'Account Information',
        icon: 'setting-user.svg',
        logo: 1,
        link: '/settings/profile'
    }, {
        name: 'Subscription & Billing',
        icon: 'credit-card.svg',
        logo: 2,
        link: '/settings/billing'
    }, {
        name: 'Integration',
        icon: 'integration-icon.svg',
        logo: 3,
        link: '/settings/integration'
    },
    //     {
    //     name: 'Users',
    //     icon: 'user-group.svg',
    //     logo: 4,
    //     link: '/settings/users'
    // },
    //     {
    //     name: 'Stage',
    //     icon: 'stage_icon.svg',
    //     logo: 5,
    //     link: '/settings/stage'
    // },
    //     {
    //     name: 'Notification',
    //     icon: 'notifications.svg',
    //     logo: 6,
    //     link: '/settings/notification'
    // },
    //     {
    //     name: 'Global video settings',
    //     icon: 'notifications.svg',
    //     logo: 7,
    //     link: '/settings/privacy'
    // },
    //     {
    //     name: 'Earnings & Payouts',
    //     icon: 'notifications.svg',
    //     logo: 7,
    //     link: '/settings/user_referral'
    // }
    ],
    scroll_option: {
        maxScrollbarLength: 500,
        swipeEasing: true
    },
    countries: countries,
    timezones: timezones,
    languages: languages,
    integrationLists: {
        aweber: {
            name: 'Aweber',
            parent: 'email',
            icon: 'Aweber.svg',
            description: 'Aweber Integration',
            auth: process.env.BACKEND_URL + 'api/oauth/aweber'
        },
        mailchimp: {
            name: 'MailChimp',
            parent: 'email',
            icon: 'Mailchimp.svg',
            description: 'MailChimp Integration',
            auth: process.env.BACKEND_URL + 'api/oauth/mailchimp',
        },
        getresponse: {
            name: 'GetResponse',
            parent: 'email',
            icon: 'GetResponse.svg',
            description: 'GetResponse Integration',
            auth: process.env.BACKEND_URL + 'api/oauth/getresponse',
        },
        keap: {
            name: 'Keap',
            parent: 'email',
            icon: 'Keap - Infusionsoft.svg',
            description: 'Keap Integration',
            auth: process.env.BACKEND_URL + 'api/oauth/keap',
        },
        convertkit: {
            name: 'ConvertKit',
            parent: 'email',
            icon: 'Convertkit.svg',
            description: 'ConvertKit Integration',
            auth: {
                action: '/oauth/convertkit',
                inputs: [
                    {
                        name: 'API Key',
                        type: 'text',
                        placeholder: 'Your ConvertKit API Key',
                        key: 'APIkey',
                        model: ''
                    },
                    {
                        name: 'API Secret Key',
                        type: 'text',
                        placeholder: 'Your ConvertKit API Secret',
                        key: 'APISecretKey',
                        model: ''
                    }
                ],
            },
        },
        activecampaign: {
            name: 'ActiveCampaign',
            parent: 'email',
            icon: 'ActiveCampaign.svg',
            description: 'ActiveCampaign Integration',
            auth: {
                action: '/oauth/activecampaign',
                inputs: [
                    {
                        name: 'API Url',
                        type: 'text',
                        placeholder: 'Your ActiveCampaign API Url',
                        key: 'APIUrl',
                        model: ''
                    },
                    {
                        name: 'API Key',
                        type: 'text',
                        placeholder: 'Your ActiveCampaign API Key',
                        key: 'APIKey',
                        model: ''
                    }
                ],
                error: false,
                errorMessage: ''
            },
        },
        gotowebinar: {
            name: 'GoToWebinar',
            parent: 'webinar',
            icon: 'GotoWebinar.svg',
            description: 'GoToWebinar Integration',
            auth: process.env.BACKEND_URL + 'api/oauth/gotowebinar'
        },
        zoom: {
            name: 'Zoom',
            parent: 'webinar',
            icon: 'Zoom.svg',
            description: 'Zoom Integration',
            auth: process.env.BACKEND_URL + 'api/oauth/zoom',
        },
        webinarjam: {
            name: 'WebinarJam',
            parent: 'webinar',
            icon: 'WebinarJam.svg',
            description: 'WebinarJam Integration',
            auth: {
                action: '/oauth/webinarjam',
                inputs: [
                    {
                        name: 'API Key',
                        type: 'text',
                        placeholder: 'Your WebinarJam API Key',
                        key: 'APIKey',
                        model: ''
                    }
                ]
            },
        },
        zoho: {
            name: 'Zoho CRM',
            parent: 'crm',
            icon: 'zoho CRM.svg',
            description: '',
            auth: process.env.BACKEND_URL + 'api/oauth/zoho',
        },
        // salesforce: {
        //     name: 'Salesforce CRM',
        //     parent: 'crm',
        //     icon: 'Salesforce CRM.svg',
        //     description: '',
        //     auth: '',
        // },
        // pardot: {
        //     name: 'Pardot',
        //     parent: 'crm',
        //     icon: 'salesforce-pardot.svg',
        //     description: '',
        //     auth: '',
        // },
        // marketo: {
        //     name: 'Marketo',
        //     parent: 'crm',
        //     icon: 'marketo-an-adobe-company.svg',
        //     description: '',
        //     auth: '',
        // },
        hubspot: {
            name: 'HubSpot',
            parent: 'crm',
            icon: 'Hubspot.svg',
            description: '',
            auth: {
                action: '/oauth/hubspot',
                inputs: [
                    {
                        name: 'API Key',
                        type: 'text',
                        placeholder: 'Your HubSpot API Key',
                        key: 'apiKey',
                        model: ''
                    }
                ]
            },
        },
        facebook: {
            name: 'Facebook',
            parent: 'social_media',
            icon: 'Facebook.svg',
            description: '',
            auth: '',
        },
        twitter: {
            name: 'Twitter',
            parent: 'social_media',
            icon: 'Twitter.svg',
            description: '',
            auth: '',
        },
        youtube: {
            name: 'Youtube',
            parent: 'social_media',
            icon: 'YouTube.svg',
            description: '',
            auth: '',
        },
        linkedin: {
            name: 'LinkedIn',
            parent: 'social_media',
            icon: 'LinkedIn.svg',
            description: '',
            auth: '',
        },
        zapier: {
            name: 'Zapier',
            parent: 'other',
            icon: 'Zapier.svg',
            description: 'Zapier Integration',
            auth: {
                action: '/oauth/zapier',
                inputs: [
                    {
                        name: '',
                        type: 'hidden',
                        placeholder: 'Your Zapier API Key',
                        key: 'apiKey',
                        model: ''
                    }
                ]
            },
        },
        vimeo: {
            name: 'Vimeo',
            parent: 'other',
            icon: 'Vimeo.svg',
            description: 'Vimeo Integration',
        },
        wistia: {
            name: 'Wistia',
            parent: 'other',
            icon: 'wistia.svg',
            description: 'Wistia Integration',
        },
        googledrive: {
            name: 'Googledrive',
            parent: 'other',
            icon: 'googledrive.svg',
            description: 'Googledrive Integration',
        },
        dropbox: {
            name: 'Dropbox',
            parent: 'other',
            icon: 'dropbox.svg',
            description: 'Dropbox Integration',
        },
        onedrive: {
            name: 'Onedrive',
            parent: 'other',
            icon: 'onedrive.svg',
            description: 'Onedrive Integration',
        },

    },
    mailerCategory: [{
        key: 'email', name: 'Email', child: ['aweber', 'mailchimp', 'getresponse', 'keap', 'convertkit', 'activecampaign']
    }, {
        key: 'webinar', name: 'Webinar', child: ['gotowebinar', 'zoom', 'webinarjam']
    }, {
        key: 'crm', name: 'CRM', child: ['zoho', 'salesforce', 'pardot', 'marketo', 'hubspot']
    }, {
        key: 'social_media', name: 'Social Media', child: ['facebook', 'twitter', 'youtube', 'linkedin']
    }, {
        key: 'other', name: 'Other', child: ['zapier', 'vimeo']
    }],
    errorsForm: {
        first_name: "First name is required.",
        name: "Are you sure you entered your name correctly?",
        invalidEmail: "Invalid Email Address.",
        email: "This email is already registered. Please try a different email address.",
        curr_password: "Current password invalid.",
        password: "Use 8 characters or more with a mix of letters, numbers & symbols.",
        password_confirmation: "Password does not match.",
        firstNameRequired: false,
        nameInvalid: false,
        emailRequired: false,
        emailInvalid: false,
        emailExist: false,
        currPasswordInvalid: false,
        passwordInvalid: false,
        passwordConfirmInvalid: false,
        passwordType: {
            uppercase: false,
            lowercase: false,
            number: false,
            special: false,
            minimum: false
        }
    },
    subscription: null,
    billingStatus: null,
    usageData: null,
    billingEstimate: null,
    subscriptionPlans: null,
    billingInformation: null,
    billingInvoices: null,
    invoiceDetails: null,
    isMobile: false
};

export default state;
