import VideoUploadProgressing from '../components/Video/VideoUploadProgressing/VideoUploadProgressing.vue';
// import ProjectRoutes from './ChildRoutes/ProjectRoutes';

// const ProjectDefault = () =>
//     import(
//         /* webpackChunkName: "AllProjects" */ '@/components/Project/AllProjects/ProjectDefault/ProjectDefault.vue'
//     );
const PowerPlayerDefault = () =>
    import(
        '@/components/PowerPlayer/PowerPlayerDefault.vue'
    );
// const Dashboard = () =>
//     import(/* webpackChunkName: "dashboard" */ '@/components/Dashboard.vue');
// const Project = () =>
//     import(/* webpackChunkName: "project" */ '@/components/Project.vue');
// const ProjectView = () =>
//     import(
//         /* webpackChunkName: "projectView" */ '@/components/Project/ProjectView/ProjectView.vue'
//     );
const VideoEditor = () =>
    import(
        /* webpackChunkName: "videoEditor" */ '@/components/VideoEditor.vue'
    );
// const Stage = () =>
//     import(/* webpackChunkName: "stage" */ '@/components/Stage.vue');
// const StagePublic = () =>
//     import(
//         /* webpackChunkName: "stage-public" */ '@/components/StagePublic.vue'
//     );
// const Contacts = () =>
//     import(/* webpackChunkName: "contacts" */ '@/components/Contacts.vue');
const Analytics = () =>
    import(/* webpackChunkName: "analytics" */ '@/components/Analytics.vue');
const Settings = () =>
    import(/* webpackChunkName: "settings" */ '@/components/Settings.vue');
// const Admin = () => import('@/components/Admin.vue');
// const Users = () => import('@/components/Admin/UsersTab/Users');
// const Partners = () => import('@/components/Admin/Partners');
//
// const UserInfo = () => import('@/components/Admin/UsersTab/UserInfo/UserInfo');
// const PartnersInfo = () => import('@/components/Admin/Partners/PartnersInfo/PartnersInfo');
// const NewUser = () => import('@/components/Admin/UsersTab/NewUser/NewUser');
// const ChangeUserSubscriptionPlan = () => import('@/components/Admin/UsersTab/UserInfo/ChangeUserSubscriptionPlan');

const SubscriptionPlan = () =>
    import(
        /* webpackChunkName: "settings" */ '@/components/Settings/Items/Subscription/SubscriptionPlan.vue'
    );
const ChangeSubscriptionPlan = () =>
    import(
        /* webpackChunkName: "settings" */ '@/components/Settings/Items/Subscription/ChangeSubscriptionPlan.vue'
    );

const BillingEstimate = () =>
    import(
        /* webpackChunkName: "settings" */ '@/components/Settings/Items/Billings/BillingEstimate.vue'
    );
const BillingInformationPage = () =>
    import(
        /* webpackChunkName: "settings" */ '@/components/Settings/Items/Billings/BillingInformationPage.vue'
    );
const BillingReceipt = () =>
    import(
        /* webpackChunkName: "settings" */ '@/components/Shared/BillingInformation/BillingReceipt.vue'
    );
// const BillingMainInfo = () =>
//     import('@/components/Admin/UsersTab/UserInfo/BillingMainInfo.vue');
// const GeneralInfoPage = () =>
//     import('@/components/Admin/UsersTab/UserInfo/GeneralInfoPage.vue');
const AccountInformation = () =>
    import('@/components/Settings/AccountInformation.vue');

// const Snaps = () =>
// import(
//     /* webpackChunkName: "Snaps" */ '@/components/Snaps/Snaps.vue'
// );

// const SnapRecording = () =>
// import(
//     /* webpackChunkName: "Snaps" */ '@/components/Snaps/components/SnapRecording/SnapRecording.vue'
// );

// const SnapPageCreate = () =>
// import(
//     /* webpackChunkName: "Snap Page Create" */ '@/components/SnapPage/SnapPageCreate.vue'
// );

// const SnapPageView = () =>
// import(
//     /* webpackChunkName: "Snap Page View" */ '@/components/SnapPage/SnapPageView.vue'
// );

// const SnapPage = () =>
// import(
//     /* webpackChunkName: "Snap Page" */ '@/components/SnapPage/SnapPage.vue'
// );
//
// const SnapPageInSnap = () =>
// import (
//     /* webpackChunkName: "SnapPage" */ '@/components/Snaps/components/SnapPage/SnapPage.vue'
// );
//
// const SharedSnaps = () =>
// import(
//     /* webpackChunkName: "Snaps" */ '@/components/Snaps/components/SharedSnaps/SharedSnaps.vue'
// );
//
// const SharedSnapVideos = () =>
// import(
//     /* webpackChunkName: "Snaps" */ '@/components/Snaps/components/SharedSnaps/SharedSnapVideos.vue'
// );

const PageNotFound = () => 
import (
    /* webpackChunkName: "404" */ '@/components/Errors/PageNotFound.vue'
);

// const Rooms = () =>
// import (
//     /* webpackChunkName: "Rooms" */ '@/components/Rooms/Rooms.vue'
// );
//
// const RoomPage = () =>
// import (
//     /* webpackChunkName: "Rooms" */ '@/components/Rooms/Layout/components/RoomPage/RoomPage.vue'
// );

const PublicVideoPage = () =>
    import(
        /* webpackChunkName: "Public video" */ '@/components/Video/PublicVideo.vue'
        );

const EmailUpdate = () =>
        import (
            /* webpackChunkName: "Email Update" */  '@/components/EmailUpdate.vue'
        );

// const Transactions = () => import('@/components/Admin/Transactions/Transactions.vue');
//
// const Sales = () => import('@/components/Admin/Transactions/Sales.vue');
// const Sales = () => import('@/components/Admin/Transactions/Sales.vue');
import WhiteBoard from '../components/WhiteBoard.vue'
import WhiteBoardDashBoard from '../components/4kplayer/WhiteBoardDashBoard.vue'
import WhiteBoardUsers from '../components/4kplayer/WhiteBoardUsers.vue'
import WhiteBoardCreateUsers from '../components/4kplayer/WhiteBoardCreateUsers.vue'
import WhiteBoardPlans from '../components/4kplayer/WhiteBoardPlans.vue'
import WhiteBoardCreatePlans from '../components/4kplayer/WhiteBoardCreatePlans.vue'
import WhiteBoardMovePlans from '../components/4kplayer/WhiteBoardMovePlans.vue'
import WhiteBoardEditPlans from '../components/4kplayer/WhiteBoardEditPlans.vue'
import WhiteBoardSettings from '../components/4kplayer/WhiteBoardSettings.vue'

import WhiteBoardSettingsBranding from '../components/4kplayer/settings/WhiteBoardSettingsBranding.vue'
import WhiteBoardSettingsEmail from '../components/4kplayer/settings/WhiteBoardSettingsEmail.vue'
import WhiteBoardSettingsIntegration from '../components/4kplayer/settings/WhiteBoardSettingsIntegration.vue'
import WhiteBoardSettingsUserCrm from '../components/4kplayer/settings/WhiteBoardSettingsUserCrm.vue'

const main_routes = [
    // {
    //     path: '/',
    //     name: 'Dashboard',
    //     component: Dashboard,
    //     meta: {
    //         title: 'Dashboard',
    //         auth: true
    //     }
    // },
    {
        path: '/',
        name: 'PowerPlayer',
        component: PowerPlayerDefault,
        meta: {
            title: 'Power Player',
            auth: true
        }
    },
    {
        path: '/white-label',
        name: 'WhiteBoard',
        component: WhiteBoard,
        children:[

            {
                path: '/white-label/dash-board',
                name: 'WhiteBoardDashBoard',
                component: WhiteBoardDashBoard,
                // meta: {
                //     title: 'Stage Public Video Lists',
                //     auth: false
                // }
            },
            {
                path: '/white-label/users',
                name: 'WhiteBoardUsers',
                component: WhiteBoardUsers,
                // meta: {
                //     title: 'Stage Public Video Lists',
                //     auth: false
                // }
            },
            {
                path: '/white-label/users/create',
                name: 'WhiteBoardUsers',
                component: WhiteBoardCreateUsers,
                // meta: {
                //     title: 'Stage Public Video Lists',
                //     auth: false
                // }
            },
            {
                path: '/white-label/plans',
                name: 'WhiteBoardPlans',
                component: WhiteBoardPlans,
                // meta: {
                //     title: 'Stage Public Video Lists',
                //     auth: false
                // }
            },
            {
                path: '/white-label/plans/create',
                name: 'WhiteBoardCreatePlans',
                component: WhiteBoardCreatePlans,
                // meta: {
                //     title: 'Stage Public Video Lists',
                //     auth: false
                // }
            },
            {
                path: '/white-label/plans/edit/:id',
                name: 'WhiteBoardEditPlans',
                component: WhiteBoardEditPlans,
                // meta: {
                //     title: 'Stage Public Video Lists',
                //     auth: false
                // }
            },
            {
                path: '/white-label/plans/move/:id',
                name: 'WhiteBoardMovePlans',
                component: WhiteBoardMovePlans,
                // meta: {
                //     title: 'Stage Public Video Lists',
                //     auth: false
                // }
            },
            {
                path: '/white-label/settings',
                name: 'WhiteBoardSettings',
                component: WhiteBoardSettings,
                children:[
                    {
                        path: '/white-label/settings/branding',
                        name: 'WhiteBoardSettingsBranding',
                        component: WhiteBoardSettingsBranding,
                    },
                    {
                        path: '/white-label/settings/user-crm',
                        name: 'WhiteBoardSettingsUserCrm',
                        component: WhiteBoardSettingsUserCrm,
                    },
                    {
                        path: '/white-label/settings/email',
                        name: 'WhiteBoardSettingsEmail',
                        component: WhiteBoardSettingsEmail,
                    },
                    {
                        path: '/white-label/settings/integration',
                        name: 'WhiteBoardSettingsIntegration',
                        component: WhiteBoardSettingsIntegration,
                    },
                ]
                // meta: {
                //     title: 'Stage Public Video Lists',
                //     auth: false
                // }
            },
        ]
        // meta: {
        //     title: 'Stage Public Video Lists',
        //     auth: false
        // }
    },
    {
        path: '/404',
        name: 'PageNotFound',
        component: PageNotFound,
        meta: {
            title: 'Not Found',
            auth: false
        }
    },
    // {
    //     path: '/projects',
    //     name: 'AllProject',
    //     component: ProjectDefault,
    //     meta: {
    //         title: 'All Projects',
    //         auth: true
    //     }
    // },
    // {
    //     path: '/all-project',
    //     name: 'AllProject',
    //     component: ProjectDefault,
    //     meta: {
    //         title: 'All Projects',
    //         auth: true
    //     }
    // },
    {
        path: '/power-player',
        name: 'PowerPlayer',
        component: PowerPlayerDefault,
        meta: {
            title: 'Power Player',
            auth: true
        }
    },
    // {
    //     path: '/snaps',
    //     name: 'Snaps',
    //     component: Snaps,
    //     meta: {
    //         title: 'SnapByte',
    //         auth: true
    //     }
    // },
    // {
    //     path: '/snaps/pages',
    //     name: 'SnapPages',
    //     component: Snaps,
    //     meta: {
    //         title: 'SnapByte',
    //         auth: true
    //     }
    // },
    // {
    //     path: '/snaps/shared-snaps',
    //     name: 'SharedSnaps',
    //     component: SharedSnaps,
    //     meta: {
    //         title: 'SharedSnaps',
    //         auth: true
    //     }
    // },
    // {
    //     path: '/snaps/shared-snap/:label_id',
    //     name: 'SharedSnapVideos',
    //     component: SharedSnapVideos,
    //     meta: {
    //         title: 'SharedSnapVideos',
    //         auth: true
    //
    //     }
    // },
    // {
    //     path: '/snaps/recording/:project_id',
    //     name: 'SnapsRecording',
    //     component: SnapRecording,
    //     meta: {
    //         title: 'SnapByte',
    //         auth: true
    //     }
    // },
    // {
    //     path: '/snaps/create/:shared_id',
    //     name: 'SnapSharedRecording',
    //     component: SnapRecording,
    //     meta: {
    //         title: 'SnapRecording',
    //         auth: false
    //     }
    // },
    // {
    //     path: '/snappage/create/:id',
    //     name: 'Snap Page Create',
    //     component: SnapPageCreate,
    //     meta: {
    //         title: 'Create Snap Page',
    //         auth: true
    //     }
    // },
    // {
    //     path: '/pitch/:id',
    //     name: 'SnapPageView',
    //     component: SnapPageView,
    //     meta: {
    //         title: 'Snap Page',
    //         auth: false
    //     }
    // },
    // {
    //     path: '/snappage',
    //     name: 'SnapPage',
    //     component: SnapPage,
    //     meta: {
    //         title: 'Snap Page Dashboard',
    //         auth: true
    //     }
    // },
    // {
    //     path: '/snaps/view/:id',
    //     name: 'SnapPage',
    //     component: SnapPageInSnap,
    //     meta: {
    //         title: 'SnapPage',
    //         auth: true
    //     }
    // },
    // {
    //     path: '/rooms',
    //     name: 'Rooms',
    //     component: Rooms,
    //     meta: {
    //         title: 'Rooms',
    //         auth: true
    //     }
    // },
    // {
    //     path: '/rooms/edit/:id',
    //     name: 'RoomEditPage',
    //     component: RoomPage,
    //     meta: {
    //         title: 'Room',
    //         auth: true
    //     }
    // },
    // {
    //     path: '/rooms/:id',
    //     name: 'RoomPage',
    //     component: RoomPage,
    //     meta: {
    //         title: 'Room',
    //         auth: false
    //     }
    // },
    {
        path: '/email-update/:hash',
        name: 'EmailUpdate',
        component: EmailUpdate,
        meta: {
            title: 'Email Update Verification',
            auth: false,
        }
    },
    // ProjectRoutes,
    {
        path: '/video-upload-progressing/:id',
        name: 'VideoUploadProgressing',
        component: VideoUploadProgressing,
        props: route => ({
            hash: route.params.hash
        }),
        meta: {
            title: 'Project View',
            auth: true
        }
    },
    // {
    //     path: '/projects/:projectId/edit/:id',
    //     name: 'VideoEditor',
    //     component: VideoEditor,
    //     meta: {
    //         title: 'Video Editor',
    //         auth: true
    //     }
    // },
    {
        path: '/power-player/edit/:id',
        name: 'PowerPlayerEditor',
        component: VideoEditor,
        meta: {
            title: 'Power Player Editor',
            auth: true
        }
    },
    // {
    //     path: '/stage',
    //     name: 'Stage',
    //     component: Stage,
    //     meta: {
    //         title: 'Stage Home',
    //         auth: true
    //     }
    // },
    // {
    //     path: '/stage/:section',
    //     name: 'StageList',
    //     component: Stage,
    //     meta: {
    //         title: 'Video Lists',
    //         auth: true
    //     }
    // },
    // {
    //     path: '/stage/:section/:video_id',
    //     name: 'StageVideo',
    //     component: Stage,
    //     meta: {
    //         title: 'Stage Video',
    //         auth: true
    //     }
    // },
    // {
    //     path: '/stage-public/:id',
    //     name: 'StagePublic',
    //     component: StagePublic,
    //     meta: {
    //         title: 'Stage Public Home',
    //         auth: false
    //     }
    // },
    // {
    //     path: '/stage-public/:id/:section',
    //     name: 'StagePublic',
    //     component: StagePublic,
    //     meta: {
    //         title: 'Stage Public Video Lists',
    //         auth: false
    //     }
    // },
    // {
    //     path: '/stage-public/:id/:section/:video_id',
    //     name: 'StageVideo',
    //     component: StagePublic,
    //     meta: {
    //         title: 'Stage Public Video',
    //         auth: false
    //     }
    // },
    {
        path: '/video/:video_id',
        name: 'PublicVideo',
        component: PublicVideoPage,
        meta: {
            title: 'Public video page',
            auth: false
        }
    },
    // {
    //     path: '/contacts',
    //     name: 'Contacts',
    //     component: Contacts,
    //     meta: {
    //         title: 'Contacts',
    //         auth: true
    //     }
    // },
    // {
    //     path: '/contacts/:section',
    //     name: 'Contacts1',
    //     component: Contacts,
    //     meta: {
    //         title: 'Contacts',
    //         auth: true
    //     }
    // },
    // {
    //     path: '/contacts/:section/:contact_id',
    //     name: 'ContactsDetail',
    //     component: Contacts,
    //     meta: {
    //         title: 'Contact Detail',
    //         auth: true
    //     }
    // },
    {
        path: '/settings',
        name: 'Settings',
        component: Settings,
        meta: {
            title: 'Settings',
            auth: true
        }
    },
    {
        path: '/settings/:section',
        name: 'SettingsSection',
        component: Settings,
        meta: {
            title: 'Settings',
            auth: true
        },
        children: [
            {
                path: '/settings/:section/change-subscription-plan',
                name: 'ChangeSubscriptionPlan',
                component: ChangeSubscriptionPlan,
                meta: {
                    title: 'ChangeSubscriptionPlan',
                    auth: true
                },
            },
            {
                path: '/settings/:section/estimate',
                name: 'BillingEstimate',
                component: BillingEstimate,
                meta: {
                    title: 'BillingEstimate',
                    auth: true
                },
                beforeEnter(to, from, next) {
                    if (from.path === '/settings/billing') {
                        next();
                    } else {
                        next({
                            name: 'SubscriptionPlan'
                        });
                    }
                }
            },
            {
                path: '/settings/:section/information',
                name: 'BillingInformationPage',
                component: BillingInformationPage,
                meta: {
                    title: 'BillingInformationPage',
                    auth: true
                }
            },
            {
                path: '/settings/:section/receipt/:receiptID',
                name: 'BillingReceipt',
                component: BillingReceipt,
                meta: {
                    title: 'BillingReceipt',
                    auth: true
                }
            },
            {
                path: '/settings/:section',
                name: 'SubscriptionPlan',
                component: SubscriptionPlan,
                meta: {
                    title: 'SubscriptionPlan',
                    auth: true
                }
            }
        ]
    },
    {
        path: '/analytics/',
        name: 'Analytics',
        component: Analytics,
        meta: {
            title: 'Analytics',
            auth: true
        }
    },
    {
        path: '/analytics/:section',
        name: 'AnalyticsView',
        component: Analytics,
        meta: {
            title: 'Analytics',
            auth: true
        }
    },
    // {
    //     path: '/admin/partners',
    //     name: 'Partners',
    //     component: Admin,
    //     meta: {
    //         title: 'Partners',
    //         auth: false
    //     },
    //     children: [
    //         {
    //             path: '/',
    //             component: Partners
    //         },
    //     ]
    // },
    // {
    //     path: '/admin/transactions',
    //     name: 'TransactionsAndSales',
    //     component: Admin,
    //     meta: {
    //         title: 'TransactionsAndSales',
    //         auth: false
    //     },
    //     children: [
    //         {
    //             path: '/',
    //             component: Transactions,
    //             name: 'Transactions',
    //         },
    //     ]
    // },
    // {
    //     path: '/admin/sales',
    //     name: 'Sales',
    //     component: Admin,
    //     meta: {
    //         title: 'Sales',
    //         auth: false
    //     },
    //     children: [
    //         {
    //             path: '/',
    //             component: Sales,
    //             name: 'Sales',
    //         },
    //     ]
    // },
    // {
    //     path: '/admin',
    //     redirect: '/admin/active'
    // },
    // {
    //     path: '/admin',
    //     name: 'AdminView',
    //     component: Admin,
    //     meta: {
    //         auth: false
    //     },
    //     children: [
    //         // {
    //         //     path: '/partners',
    //         //     component: Partners
    //         // },
    //         {
    //             path: 'users/:id',
    //             component: UserInfo
    //         },
    //         {
    //             path: 'partners/:id',
    //             component: PartnersInfo
    //         },
    //         {
    //             path: 'user/:id/changeplan',
    //             component: ChangeUserSubscriptionPlan
    //         },
    //         {
    //             path: 'new_user',
    //             component: NewUser
    //         },
    //         {
    //             path: ':section/:id/user-info',
    //             component: GeneralInfoPage,
    //             children: [
    //                 {
    //                     path: 'billing-history',
    //                     component: BillingMainInfo
    //                 },
    //                 {
    //                     path: 'receipt/:receiptId',
    //                     component: BillingReceipt
    //                 },
    //                 {
    //                     path: 'account-info',
    //                     component: AccountInformation
    //                 }
    //             ]
    //         },
    //         {
    //             path: ':section/:id',
    //             component: Users
    //         },
    //         {
    //             path: ':section',
    //             component: Users
    //         },
    //         {
    //             path: ':section',
    //             component: Partners
    //         }
    //     ]
    // },
    // {
    //     path: '/:id',
    //     name: 'StagePublic',
    //     component: StagePublic,
    //     meta: {
    //         title: 'Stage Public Home',
    //         auth: false
    //     },
    //
    // }, {
    //     path: '/:id/:section/:video_id',
    //     name: 'StageVideo',
    //     component: StagePublic,
    //     meta: {
    //         title: 'Stage Public Video',
    //         auth: false
    //     }
    // },
    // {
    //     path: '/:id/:section',
    //     name: 'StagePublic',
    //     component: StagePublic,
    //     meta: {
    //         title: 'Stage Public Video Lists',
    //         auth: false
    //     }
    // },
   
];

export default main_routes;
